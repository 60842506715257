import { useEffect, useState } from "react";
import {setForm} from '../Redux/Reducers/formReducer';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, Link, useParams} from "react-router-dom";
import Loading from "../Components/loading";
import HTMLReactParser from 'html-react-parser';
import Api from '../HelperFunction/Api2';

const Home = () =>{
    const dispatch = useDispatch();
    const form = useSelector(store=>store.form.value)
    const [showLoading, setShowLoading] = useState(true);
    const {d} = useParams();

    const navigate = useNavigate();

    useEffect(()=>{
        //console.log("good")
        const getData = () =>{
            let formId = d
            let formDetails = d.split('?');
            if(formDetails.length > 1){
                formId = formDetails[0]
            }
            Api.getApi(`/form/get-form/${formId}`)
            .then(result=>{
                console.log(result,'response')
                if(result.isSuccessful){
                    
                    dispatch(setForm(result.data));
                    setShowLoading(false);

                    if(!form.add_instruction){
                        navigate(`/form/${d}`)
                    }
                }
                else{
                    alert(result.message)

                    if(result.is_redirect){
                        window.location.href= result.data;
                    }
                    //else window.location.href="https://sight-eduplus.com"
                }
            })
        }

        getData();
    }, [d])

    return (
        <>
            <Loading shouldShow={showLoading} />
            <div className="noteContentMain">
                {
                    form.add_instruction && (
                        <>
                            <div className="noteContent">
                                {
                                    HTMLReactParser(form.instruction)
                                }
                            </div>
                            <div>
                                <Link to="form">Next</Link>
                            </div>
                        </>
                        
                    )
                }
                
            </div>
        </>
    )
}

export default Home;